<!--
 * @Descripttion: 登录页面
 * @version: 
 * @Author: alex.yang
 * @Date: 2022-12-23 09:40:24
 * @LastEditors: alex.yang
 * @LastEditTime: 2023-06-09 09:55:26
-->
<template>
    <div class="defaultV2-signIn" v-loading="loading" 
        element-loading-text="登录中..."
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.5)">
        <star-background />
        <!-- <div class="logo-wrap">
            <img class="logo" src="@/assets/logo.png" />
        </div> -->
        <div class="line"></div>
        <div class="sing-wrap">
            <!-- <div class="title">
                <div class="name">欢迎登录</div>
            </div> -->
            <div class="right-plan">
                <div class="loginForm">
                    <div class="login-title">助店通电脑端</div>
                    <el-form ref="form" :model="loginForm" label-position='top'>
                        <el-form-item label="">
                            <el-input v-model="loginForm.mobile" placeholder="请输入助店通使用手机号"></el-input>
                        </el-form-item>
                        <el-form-item label="">
                            <el-input type="password" show-password v-model="loginForm.password" placeholder="请输入密码"></el-input>
                        </el-form-item>
                        <el-form-item label="">
                            <div class="code-wrap">
                                <el-input class="code" v-model="loginForm.code" placeholder="请输入验证码"></el-input>
                                <div class="code-tig" @click="clickCode">
                                    <img v-if="codeUrl != ''" class="img" :src="codeUrl" />
                                </div>
                            </div>
                        </el-form-item>
                    </el-form>
                </div>
                <div class="sign-btn" @click="handleLogin">登录</div>
            </div>
        </div>
        <div class="foot-wrap">
            <div>©2020 助店通 版权所有 新ICP备18001373号-4</div>
            <el-link :underline="false" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=65010402001730" target="_blank">
                <div class="link-wrap">
                    <img src="http://www.zhudiantong.com/img/public_security_icon.png" width="20" height="20" alt="备案图标" />
                    <div class="text">公安备案号：65010402001730</div>
                </div>
            </el-link>
        </div>
    </div>
</template>

<script>
import common from "@/utils/common.js"
import StarBackground from './starBackground'
import axios from "axios";
export default {
    components: { StarBackground },
    data() {
        return {
            loginForm:{
                mobile:'',
                password:'',
                code: '',
            },
            codeUrl: '',
            loading: false
        }
    },
    beforeCreate: function() {
        document.getElementsByTagName('body')[0].className = 'body-bg'
    },
    mounted(){
        this.keyupSubmit();
        this.getVerify();
    },
    destroyed(){
      //取消键盘监听事件
        document.onkeydown = null
    },
    methods: {
        keyupSubmit() { 
            document.onkeydown = (e) => {
                let _key = window.event.keyCode;
                if (_key === 13&&!this.clickState) {
                    this.handleLogin();
                }
            };
        },
        // 点击获取验证码
        clickCode(){
            this.getVerify();
        },
        getVerify(){
            this.codeUrl = ''
            let url = common.apiServer+'/rafflev1/auth/verify'
            axios({
                url: url,
                method: 'get',
                responseType: "blob"
            }).then((res)=> {
                this.$set(this,'codeUrl',res.config.url)
            })
        },
        // 登录
        handleLogin(){
            if(this.loginForm.mobile === ''){
                this.$message.warning('请输入账号');
                return
            }
            if(this.loginForm.password === ''){
                this.$message.warning('请输入密码');
                return
            }
            if(this.loginForm.code === ''){
                this.$message.warning('请输入验证码');
                return
            }
            let params = {
                username: this.loginForm.mobile,
                password: this.loginForm.password,
                captcha: this.loginForm.code
            }
            this.loading = true;
            common.connect("/rafflev1/auth/login",params,(res)=>{
                if(res.isSuccess == 1){
                    localStorage.setItem('token', res.data.token);
                    localStorage.setItem('userInfo', JSON.stringify(res.data));
                    this.$message.success("登录成功");
                    this.$router.replace("/");
                }
                this.getVerify();
                this.loginForm.code = '';
                this.loading = false;
            });
        },
        logout(){
            common.connect("/distributorpcv1/auth/logout",{},(res)=>{
                localStorage.clear()
            });
        }
    }
}
</script>

<style lang="scss">
.body-bg {
  background-attachment: fixed;
  overflow: hidden;
}
@keyframes bganimation {
    0%{
        background-position: 0% 50%;
    }
    50%{
        background-position: 100% 50%;
    }
    100%{
        background-position: 0% 50%;
    }
}
.defaultV2-signIn{
    width: 100%;
    height: 100%;
    background-image: linear-gradient(45deg,#56ccf2,#2f80ed,#b2fefa,#0ed2f7,#6dd5fa);
    background-size: 400%;
    animation: bganimation 15s infinite;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    .line{
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.1);
    }
    .logo-wrap{
        position: absolute;
        top: 20px;
        left: 20px;
        .logo{
            width: 60px;
        }
    }
    .sing-wrap{
        width: 1000px;
        height: 600px;
        display: flex;
        flex-direction: column;
        z-index: 999;
        background-image: url(../../assets/background-img.png);
        background-size: 100% 100%;
        border-radius: 40px;
        box-shadow: 0px 0px 1px #ccc;
        .title{
            width: 100%;
            font-size: 25px;
            margin-top: 5px;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px saddlebrown solid 1px;
            .name{
                font-weight: 600;
                margin-top: 15px;
            }
        }
        .right-plan{
            width: 460px;
            height: 100%;
            padding: 20px 40px 50px 40px;
            box-sizing: border-box;
            margin-left: 80px;
            .login-title{
                font-size: 26px;
                color: #3566fe;
                font-weight: normal;
                padding-top: 40px;
                padding-bottom: 30px;
            }
            .loginForm{
                .el-form-item__label{
                    line-height: 30px;
                    padding:0 0 0 0;
                    color: #7D9EB5;
                }
                .el-form-item{
                    margin-bottom: 30px;
                }
                .el-input__inner{
                    border: 1px solid #8bbbfd;
                    height: 50px;
                    line-height: 50px;
                    border-radius: 50px;
                }
                .el-input__inner:focus{
                    border-color: #CCDFEA
                }
                .code-wrap{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    .code{
                        width: calc(100% - 200px);
                    }
                    .code-tig{
                        width: 120px;
                        height: 45px;
                        line-height: 45px;
                        text-align: center;
                        border-radius: 6px;
                        border: 1px solid #ddd;
                        overflow: hidden;
                        cursor: pointer;
                        .img{
                            width: 110%;
                            height: 110%;
                            vertical-align: bottom;
                        }
                    }
                }
            }
            .sign-btn{
                color: #fff;
                border-radius: 60px;
                height: 60px;
                line-height: 60px;
                text-align: center;
                margin: 0 20px;
                // background-image: linear-gradient(to right, #40CBD1 , #07BDD3);
                background-color: #0770ff;
                margin-top: 50px;
                cursor: pointer;
                font-size: 20px;
                &:hover{
                    box-shadow: 0px 0px 2px #ccc;
                    background-color: #0770ff;
                }
            }
        }
    }
    .server-wrap{
        position: absolute;
        bottom: 60px;
        left: 50%;
        transform: translate(-50%);
        display: flex;
        flex-direction: column;
        align-items: center;
        .title{
            font-size: 16px;
            color: #fff;
        }
        .phone{
            font-size: 26px;
            color: #fff;
            margin-top: 10px;
        }
    }
    .foot-wrap{
        position: absolute;
        bottom: 20px;
        left: 50%;
        transform: translate(-50%);
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        font-size: 14px;
        .link-wrap{
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            margin-left: 10px;
            .text{
                color: #fff !important;
            }
        }
    }
}
</style>